import { Input } from "antd";
import styled, { createGlobalStyle } from "styled-components";

// Глобальные стили для темной темы
const GlobalStyles = createGlobalStyle`
  .ant-input {
    background-color: ${(props) => props.theme.background} !important; /* Темный фон */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */
    border: 1px solid ${(props) => props.theme.border} !important; /* Граница */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &::placeholder {
      color: ${(props) => props.theme.label} !important; /* Цвет плейсхолдера */
      opacity: 1;
    }

    &:hover {
      border-color: ${(props) => props.theme.hover} !important; /* Граница при наведении */
    }

    &:focus {
      border-color: ${(props) => props.theme.border} !important; /* Граница при фокусе */
      box-shadow: 0 0 0 2px ${(props) => props.theme.secondary} !important;
    }
  }

  .ant-input-affix-wrapper {
    background-color: ${(props) => props.theme.background} !important; /* Фон обертки */
    border: 1px solid ${(props) => props.theme.border} !important;
  }

  .ant-input-group-addon {
    background-color: ${(props) => props.theme.secondary} !important; /* Фон для аддона */
    color: ${(props) => props.theme.color} !important;
    border-color: ${(props) => props.theme.border} !important;
  }
`;

// Стили для InputOTP
const StyledOTPInput = styled(Input.OTP)`
  &.ant-input {
    text-align: center; /* Выравнивание текста для ввода кода */
    letter-spacing: 0.5em; /* Увеличенный интервал для ввода OTP */
  }
`;

const InputOTP = (props) => {
  return (
    <>
      <GlobalStyles />
      <StyledOTPInput {...props} />
    </>
  );
};

export default InputOTP;
