import React, { createContext, useContext, useState, useEffect } from 'react';
import makeRequest from './api/makeRequest';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import handleAsyncNotification from './helpers/handleAsyncNotification';

const AuthContext = createContext();

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true // Включаем куки для междоменных запросов
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(fetchMe, []);

  const logout = async () => {
    await makeRequest('/api/v1/auth/logout') 
    setUser(null);
  };

  function fetchMe() {
    fetch(process.env.REACT_APP_API_HOST + '/api/v1/auth/users/me', {
      method: 'GET',
      credentials: "include",
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        setUser({ id: data.id, username: data.username, role: data.role });
      })
      .catch(error => {
        console.error('Error fetching user data', error);
        setUser(null);
      })
      .finally(() => setLoading(false));
  }

  const login = async (navigate, {username, password}) => {
    await handleAsyncNotification(async () => {
      const data = await request();
      
      if(data['2FA']) {
        return navigate("/2fa");
      }
      
      fetchMe();
    }, {
      successText: "Вход успешен",
      errorText: "Ошибка входа",
      loadingText: "Вход"
    }); 

    async function request() {
      return await makeRequest('/api/v1/auth/login', {
        body: {username, password}
      });
    }
  };

  const createUser = async (username, password, email, invite_id) => {
    await makeRequest('/api/v1/core/create_user/create_account', {
      body: {
          username,
          password,
          email,
          invite_id,
      }
    })
  }
  
  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, loading, createUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};