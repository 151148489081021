import { useEffect, useState } from "react";
import ConfirmModal from "./elements/ConfirmModal";
import { toast } from "react-toastify";
import { useWebsocket } from "@shared/hooks/useWebsocket";
import {useAuth} from "@shared/AuthContext";


const TraderPayoutsConfirmator = () => {
    const {user} = useAuth();
    const websocket = useWebsocket();
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        if (websocket && user?.role === "trader") {
            websocket.onopen = () => {
                websocket.send(JSON.stringify({event: "get_trader_surveys"}));
            };
    
            websocket.onmessage = (res) => {
                const { event, data } = JSON.parse(res.data);
            
                if (event === "survey_request") {
                    setSurveys((prevSurveys) => {
                        // Создаем карту для уникальных элементов по id
                        const surveyMap = new Map();
                    
                        // Добавляем старые данные
                        prevSurveys.forEach((survey) => surveyMap.set(survey.id, survey));
                    
                        // Добавляем новые данные
                        data.forEach((survey) => surveyMap.set(survey.id, survey));
                        const result = Array.from(surveyMap.values())

                        // Преобразуем карту обратно в массив
                        return result;
                    });
                }

                for(const survey of data) {
                    const toastId = toast(<ConfirmModal closeNotification={() => {toast.dismiss(toastId)}} {...survey} />, {
                        autoClose: false,
                        closeButton: false,
                    });
                }                
            };

            websocket.onerror = (error) => {
                console.error("WebSocket ошибка:", error);
            };
    
            websocket.onclose = () => {
                console.log("WebSocket соединение закрыто. Пытаюсь переподключиться...");
            }
        }
    },[websocket, user]);

    useEffect(() => {
        if(user?.role !== "trader") {
            try {
                websocket.close();
            } catch(e) {}
        }
    }, [user]);
}

export default TraderPayoutsConfirmator;