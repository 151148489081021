import HBox from "@shared/ui/layouts/Hbox";
import VBox from "@shared/ui/layouts/Vbox";
import styled from "styled-components";
import Text from "@shared/ui/Text";


const advantages = [
    { src: "advantage_1", title: "Удобство", description: "Подходит для проектов без юридической регистрации" },
    { src: "advantage_2", title: "Гибкость", description: "Гибкие условия сотрудничества" },
    { src: "advantage_3", title: "Скорость", description: "Быстрая скорость обработки большого потока платежей" },
    { src: "advantage_4", title: "Расширяемость", description: "Новые возможности для расширения географии деятельности и увеличения доходов вашей компании" },
    { src: "advantage_5", title: "Надежность", description: "Надежная защита от санкций и штрафов за прием платежей из России и СНГ" },
    { src: "advantage_6", title: "Бренд", description: "Брендированная платежная страница под ваш проект" }
]

const Container = styled(VBox)`
    color: ${props => props.theme.color};
`

const Advantages = () => {
    return <Container width="auto" gap="20px">
        {advantages.map(({src, title, description}) => <Advantage
            src={src}
            title={title}
            description={description}
        />)}
    </Container>
}

function Advantage({src, title, description}) {
    return <HBox customJust="start" width="500px">
        <VBox gap="5px">
            <Text weight="bold">{title}</Text>
            <Text size="small">{description}</Text>
        </VBox>
    </HBox>
}

export default Advantages;