import { lazy } from "react";


const Assets = lazy(() => import("@agent/Assets"));
const Withdraw = lazy(() => import("@agent/Withdraw"));

const agentRoutes = [
    { path: "/assets", element: <Assets /> },
]

export default agentRoutes;