import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import Hbox from "@shared/ui/layouts/Hbox";
import Vbox from "@shared/ui/layouts/Vbox";
import Text from "@shared/ui/Text";
import CloseButton from "@shared/ui/buttons/CloseBtn";

// Стили для оверлея и модального окна
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.secondary};
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    gap: 10px;
    width: ${(props) => (props.modalType === 'devices' ? '90%' : 'auto')};
    min-width: 30%;
    height: ${(props) => (props.modalType === 'devices' ? '90%' : 'auto')};
    z-index: 1001;
`;

const StylesWrapper = styled.div`
    color: ${props => props.theme.color};
`

// Контекст для модальных окон
const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState([]); // Массив всех модалок

    // Закрытие конкретного модального окна по id
    const closeModal = (id) => {
        setModals((prevModals) => prevModals.filter(modal => modal.id !== id));
    };

    // Открытие модального окна с контентом и уникальным id
    const openModal = (content, title) => {
        const id = Date.now();

        setModals((prevModals) => [
            ...prevModals,
            { id, content: React.cloneElement(content, { closeModal: () => {closeModal(id)} }), title }
        ]);
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            {modals.map(({ id, content, title }) => (
                <Overlay key={id}>
                    <Modal onClick={(e) => e.stopPropagation()}>
                        <StylesWrapper>
                            <Vbox gap="15px">
                                <Hbox alignItems="center">
                                    <Text size="default" weight="bold">{title}</Text>
                                    <CloseButton onClick={() => closeModal(id)} />
                                </Hbox>
                                {content}
                            </Vbox>
                        </StylesWrapper>
                    </Modal>
                </Overlay>
            ))}
        </ModalContext.Provider>
    );
};

// Хук для работы с модальными окнами
export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};
