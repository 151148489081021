import { useEffect, useState } from "react";

export const useWebsocket = () => {
    const [websocket, setWebsocket] = useState();

    useEffect(() => {
        const ws = new WebSocket(process.env.REACT_APP_API_HOST + "/ws");

        setWebsocket(ws);

        return () => {
            try {
                websocket.close();
                ws.close();
            } catch(e) {
                console.log(e)
            }
        };
    }, []);

    return websocket;
}