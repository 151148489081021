import { Input } from "antd";
import styled, { keyframes } from "styled-components";
import Label from "@shared/ui/Label";
import Text from "@shared/ui/Text";
import makeRequest from "@shared/api/makeRequest";
import { useEffect, useState } from "react";
import Icon from "@shared/ui/icons/Icon";
import { toast } from "react-toastify";
import { useAuth } from "@shared/AuthContext";
import Hbox from "@shared/ui/layouts/Hbox";
import { useNavigate } from "react-router-dom";
import { LockFilled } from "@ant-design/icons";
import LogoIcon from "@shared/ui/icons/LogoIcon";
import InputOTP from "@shared/ui/InputOTP";

// Анимация для формы
const formAnimation = keyframes`
  from {
    transform: rotateX(-30deg);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg);
    opacity: 1;
  }
`;

// Стили для контейнера формы
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${props => props.theme.background}; // Фоновый цвет для всего экрана
  gap: 30px;
`;  

const StyledForm = styled.form`
  position: relative;
  display: flex;  
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  width: 450px;
  color: ${props => props.theme.color};
  background: ${props => props.theme.secondary};
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Исходная тень */
  transition: box-shadow 0.3s ease-in-out; /* Плавный переход тени */
  animation: ${formAnimation} 0.5s ease-in-out;
  border: solid 1px ${props => props.theme.border}; 
`;

const TwoFactorAuthPage = () => {
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    const {setUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(value.length >= 6) {
            confirmCode();
        }
    }, [value]);

    return <StyledDiv>
        <StyledForm>
            <LogoIcon />
            <Hbox customJust="center"><Icon style={{fontSize: "30px"}} icon={LockFilled} /></Hbox>
            <Text>Введите 6-значный код для входа</Text>
            <Label value="Код для входа">
                <InputOTP disabled={loading} value={value} onChange={setValue} size="large" length={6} />
            </Label>
        </StyledForm>
    </StyledDiv>

    async function confirmCode() {
        if(value.length < 6) return toast.error("Введите код");

        try {
            setLoading(true);

            const {role} = await makeRequest("/api/v1/auth/login/2fa", {
                body: {
                    temp_code: value
                }
            });
            
            if(role) {
                setUser({role});
                navigate("/assets");
            }
        } catch(e) {
            if(e.response?.status === 401) {
                navigate("/login");
            }
            console.log(e);
            toast.error("Ошибка подтверждения");
        } finally {
            setLoading(false);
            setValue("");
        }
    }
}

export default TwoFactorAuthPage;