import FormPage from "@shared/ui/widgets/FormPage";
import { useAuth } from "@shared/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { inputs } from "./lib/constants";
import Advantages from "./elements/Advantages";


const SigninPage = () => {
    const { invitationId } = useParams();
    const {login, createUser} = useAuth();
    const navigate = useNavigate();

    return <FormPage 
        title="Регистрация"
        handleSubmit={handleSubmit}
        errorText="Ошибка регистрации"
        inputs={inputs}
    >
        <Advantages />
    </FormPage>
    async function handleSubmit({username, email, password}) {
        try {
            await signin(username, password, email);
            await login(navigate, {username, password});            
            navigate('/assets');
        } catch(e) {
            console.log(e);
            toast.error("Ошибка регистрации");
        }
    };

    async function signin(username, password, email) {
        await createUser(username, password, email, invitationId);
    };
}

export default SigninPage;