import React from 'react';
import { useAuth } from '@shared/AuthContext';
import { useState } from 'react';
import styled from 'styled-components';
import Loading from '@shared/ui/Loading';
import { LogoutOutlined } from '@ant-design/icons';
import Icon from '@shared/ui/icons/Icon';



const LogoutButton = () => {
    const {logout} = useAuth();
    const [logoutLoading, setLogoutLoading] = useState(false);

    return <>
        {logoutLoading ?
        <Loading size="xsmall" />
            :
        <Icon onClick={handleLogout} icon={LogoutOutlined} />
        }
    </>

    async function handleLogout() {
        setLogoutLoading(true);
        try {
            await logout();
        } catch(e) {
            console.log(e);
        } finally {
            setLogoutLoading(false)
        }
    }
}

export default LogoutButton;