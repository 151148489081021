import FormPage from "@shared/ui/widgets/FormPage";
import { useAuth } from "@shared/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const LoginPage = () => {
  const { user, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(user) {
      navigate("/assets ")
    }
  }, [user]);

  return <FormPage
    title="Вход"
    handleSubmit={({username, password}) => {login(navigate, {username, password})}}
    inputs={[
      {
        label: "Логин",
        value: "username",
        placeholder: "Логин",
      },
      {
        label: "Пароль",
        value: "password",
        placeholder: "Пароль",
        passwordType: true
      }
    ]}
  />
}

export default LoginPage;