import axios from "axios";
import { api } from "./ApiProvider";


async function makeRequest(path, {body, method, headers} = {}) {
    const response = await api({
        url: process.env.REACT_APP_API_HOST + path,
        method: method || "POST",
        headers: headers || {
            "Content-Type": "application/json"
        },
        data: body,
        withCredentials: true
    });

    return response.data;
}

export default makeRequest;