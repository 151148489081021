import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useAuth } from '@shared/AuthContext'; // Скорее всего, путь должен быть изменен в зависимости от структуры проекта
import adminRoutes from './adminRoutes';
import merchantRoutes from './merchantRoutes';
import traderRoutes from './traderRoutes'
import supportRoutes from './supportRoutes';
import agentRoutes from './agentRoutes';

const AppRoutes = () => {
    const { user } = useAuth(); // Получаем роль пользователя из контекста
  
    // В зависимости от роли возвращаем нужные маршруты
    const routes = user.role === 'admin' ? adminRoutes : 
                   user.role === 'merchant' ? merchantRoutes : 
                   user.role === 'trader' ? traderRoutes :
                   user.role === 'support' ? supportRoutes :
                   user.role === 'agent' ? agentRoutes :
                   []; // Если нет подходящей роли, возвращаем пустой массив
  
    return (
      <>
        {useRoutes(routes)} {/* Вызов useRoutes внутри React-фрагмента */}
      </>
    );
  };

export default AppRoutes;