import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import SimpleInput from '@shared/ui/Simpleinput';
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Text from "@shared/ui/Text";
import Vbox from "@shared/ui/layouts/Vbox";
import Checkbox from '@shared/ui/Checkbox';
import Hbox from "@shared/ui/layouts/Hbox";
import { LoginOutlined } from '@ant-design/icons';
import LogoIcon from '../icons/LogoIcon';


// Анимация для формы
const formAnimation = keyframes`
  from {
    transform: rotateX(-30deg);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg);
    opacity: 1;
  }
`;

// Стили для формы
const StyledForm = styled.form`
  position: relative;
  display: flex;  
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  width: 450px;
  color: ${props => props.theme.color};
  background: ${props => props.theme.secondary};
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Исходная тень */
  transition: box-shadow 0.3s ease-in-out; /* Плавный переход тени */
  animation: ${formAnimation} 0.5s ease-in-out;
  border: solid 1px ${props => props.theme.border}; 
`;

export const Icon = styled.img`
  width: 100px;
  color: white;
`;


// Стили для контейнера формы
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${props => props.theme.background}; // Фоновый цвет для всего экрана
  gap: 30px;
`;

// Стили для отображения ошибок
const StyledError = styled.div`
  color: red;
`;

// Компонент LoginPage
const FormPage = ({title, inputs, error, handleSubmit, children}) => {
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);

    return (
        <StyledDiv>
            {children}
            <StyledForm onSubmit={handleSubmit}>
                <LogoIcon />
                <Text weight="bold" size="xlarge">{title}</Text>
                {error && <StyledError><Text>{error}</Text></StyledError>}
                <Vbox width="auto" gap="10px">
                {inputs.map(({label, value, placeholder, passwordType}) => <SimpleInput
                    label={label}
                    type="text"
                    key={value}
                    placeholder={placeholder}
                    passwordType={passwordType}
                    value={values[value]}
                    onChange={(e) => setValues(prev => ({...prev, [value]: e.target.value}))}
                />)}
                </Vbox>
                <Checkbox><Text>Запомнить меня</Text></Checkbox>
                <Hbox customJust="center">
                </Hbox>
                <SimpleButton icon={<LoginOutlined />} main loading={loading} onClick={submit} type="submit" disabled={loading}>
                    Войти
                </SimpleButton>
            </StyledForm>
        </StyledDiv>
    );

    async function submit(e) {
        e.preventDefault();
        setLoading(true);

        try {
            await handleSubmit(values);
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
};


export default FormPage;
