import React, { useEffect, useState } from "react";
import { Input, InputNumber } from "antd";
import "antd/dist/reset.css"; // Ант Дизайн стили
import styled, { css } from "styled-components";
import Label from "./Label";
import ErrorLabel from "./ErrorLabel";

// Общие стили для всех инпутов
const commonStyles = css`
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px ${(props) => props.theme.background} inset !important;
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.background} inset !important;
    -webkit-text-fill-color: ${(props) => props.theme.color} !important;
    transition: background-color 5000s ease-in-out 0s; /* Отключаем подсветку */
  }

  &:focus-within {
    border-color: ${(props) => props.theme.border} !important; /* Граница при фокусе */
    box-shadow: 0 0 0 2px ${(props) => props.theme.border} !important;
  }

  margin: 0;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  border: 1px solid ${(props) => props.theme.border};
  transition: background 0.3s, border-color 0.3s, color 0.3s;

  &:hover {
    border-color: ${(props) => props.theme.border};
    background: ${(props) => props.theme.secondary};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.secondary};
    border-color: ${(props) => props.theme.border};
    background: ${(props) => props.theme.secondary};
  }

  &::placeholder {
    color: ${(props) => props.theme.label}; /* Цвет placeholder */
    opacity: 1; /* Убедитесь, что placeholder полностью виден */
  }

  // Стили для ошибки
  &.ant-input-status-error {
    border-color: red !important; /* Красная граница при ошибке */
    background: ${(props) => props.theme.background} !important;
    color: ${(props) => props.theme.color} !important;
  }
`;

// Стили для InputNumber
const StyledInputNumber = styled(Input)`
  ${commonStyles}
  width: 100%; // Чтобы подстраиваться под ширину родителя

  .ant-input-number-input {
    background: ${(props) => props.theme.background} !important; /* Темный фон */
    color: ${(props) => props.theme.color} !important;
  }

  &:focus,
  &:hover {
    .ant-input-number-input {
      background: ${(props) => props.theme.secondary} !important; /* Темный фон при фокусе */
      color: ${(props) => props.theme.color} !important;
    }
  }

  .ant-input-number-focused {
    background: ${(props) => props.theme.secondary} !important; /* Темный фон при фокусе */
  }
`;

const NumberInput = ({ onChange, defaultValue = 0, value: outerValue, ...props }) => {
  const [value, setValue] = useState(defaultValue || 0);

  const normalize = (value) => parseInt(value);

  const handleChange = (inputValue) => {
    const parsed = normalize(inputValue);

    if (!Number.isNaN(parsed)) {
      onChange({ target: { value: parsed } }); // Эмулируем стандартное событие
      setValue(parsed);
    } else if(inputValue === "") {
      setValue(0);
    }
  };

  // Синхронизация с внешним значением
  useEffect(() => {
    if (outerValue !== undefined) {
      setValue(normalize(outerValue));
    }
  }, [outerValue]);


  return (
    <StyledInputNumber
      {...props}
      value={value}
      onChange={(e) => {handleChange(e.target.value)}} // `InputNumber` передает числовое значение напрямую
    />
  );
};

// Стили для обычного инпута
const StyledInput = styled(Input)`
  ${commonStyles}
`;

// Стили для инпута пароля
const StyledPassword = styled(Input.Password)`
  ${commonStyles}

  .ant-input {
    background: ${(props) => props.theme.background} !important; /* Темный фон */
    color: ${(props) => props.theme.color} !important; /* Цвет текста */
  }

  &:hover,
  &:focus {
    .ant-input {
      background: ${(props) => props.theme.secondary} !important; /* Темный фон при фокусе */
      color: ${(props) => props.theme.color} !important;
    }
  }

  .ant-input-password-icon {
    color: ${(props) => props.theme.label} !important; /* Цвет иконки "глаз" */
  }

  .ant-input-password-focused {
    background: ${(props) => props.theme.secondary} !important; /* Темный фон при фокусе */
  }
`;

// Компактный компонент SimpleInput
const SimpleInput = ({
  textarea,
  label,
  error,
  min,
  max,
  prefix,
  number,
  passwordType,
  ...props
}) => {
  const InputComponent = number
    ? NumberInput
    : passwordType
    ? StyledPassword
    : StyledInput;

  return (
    <ErrorLabel value={error}>
      <Label value={label}>
        <InputComponent
          min={min}
          max={max}
          status={error ? "error" : ""}
          {...props}
        />
      </Label>
    </ErrorLabel>
  );
};

export default SimpleInput;
