import styled from "styled-components";


export const HeaderContainer = styled.header`
  min-height: 60px;
  width: 100%; /* Хедер занимает всю ширину */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.background}; /* Фон хедера */
  border-radius: 15px;
  border: solid 1px ${props => props.theme.border};
  color: ${(props) => props.theme.color}; /* Цвет текста */
  border-bottom: 1px solid ${(props) => props.theme.border}; /* Нижняя граница */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Легкая тень для эффекта */
`;