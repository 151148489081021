import styled from "styled-components";

const StyledIcon = styled.div`
    color: ${(props) => props.theme.color};
    padding: 3px;
    cursor: pointer;
`;

export default function Icon({ icon: IconComponent, width, space, ...props }) {
    return (
        <StyledIcon as={IconComponent} {...props} $width={width} $space={space} />  
    );
}