import React, { Suspense, lazy } from 'react';

const SupportOrders = lazy(() => import('@src/widgets/orders'));
const SupportAssets = lazy(() => import('@src/pages/support/Assets'));
const Order = lazy(() => import('@src/widgets/Order'));
const Settings = lazy(() => import('@src/pages/support/Settings'));

const supportRoutes = [
    { path: '/orders', element: <SupportOrders /> },
    { path: '/assets', element: <SupportAssets /> },
    { path: '/orders/:order_id', element: <Order /> },
    { path: '/settings', element: <Settings /> }
];

export default supportRoutes;
