import React from 'react';
import {HeaderContainer} from "./styles";
import Icon from "@shared/ui/icons/Icon";
import LogoutButton from '@features/LogoutButton';
import Hbox from "@shared/ui/layouts/Hbox";
import { MenuOutlined } from '@ant-design/icons';


const MobileHeader = ({ toggleSidebar }) => {
  return (
    <HeaderContainer>
      <Hbox width="100%" gap="20px">
        <LogoutButton />
        <Icon style={{fontSize: "20px"}} onClick={toggleSidebar} icon={MenuOutlined} />
      </Hbox>
    </HeaderContainer>
  );
};

export default MobileHeader;
