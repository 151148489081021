import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

const StyledCode = styled(QRCode)`
  padding: 10px;
  border-radius: 10px;
  background: white;
`

const TronQRCode = ({ address, amount, message }) => {
  const tronURI = address;

  return (
    <div>
      <StyledCode 
        value={tronURI} 
        fgColor="#000000" 
        bgColor="#ffffff" 
        level="H" // Высокий уровень коррекции ошибок
      />
    </div>
  );
};

export default TronQRCode;
