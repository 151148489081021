import { toast } from 'react-toastify';


export default async function(callback, {loadingText, successText, errorText}) {
    const loadingToast = toast.loading(loadingText);

    try {
        // Выполняем запрос
        await callback();
    
        // Обновляем уведомление на успех
        toast.update(loadingToast, {
            render: successText,
            type: 'success',
            isLoading: false,
            autoClose: 3000, // Уведомление автоматически исчезнет через 3 секунды
        });
    } catch (error) {
        // Обновляем уведомление на ошибку
        toast.update(loadingToast, {
            render: errorText,
            type: 'error',
            isLoading: false,
            autoClose: 3000,
        });
    }
}