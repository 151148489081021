import { createGlobalStyle, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const colors = {
  light_primary: 'rgba(229, 235, 233, 1)',
  primary: 'RGB(245,246,250)',
  second_primary: 'rgba(0, 0, 0, 0.1)',
  secondary: 'rgba(209, 213, 219, 1)',
  accent: 'rgb(29,91,91)',
  second_accent: '#6aa69c',
  shady: "rgb(211, 211, 211)",
  page: "RGB(252,252,252)",
  purple: 'rgb(86,55,175)',
  periwinkle: 'rgb(131, 219, 198)',
  border: 'rgb(224,224,224)',
  background_light: 'white',
  ssecondary_light: "#F8F8F8",
  background_dark: "black",
  ssecondary_dark: "#141414",
  text: 'rgba(17, 25, 40, 1)',
  second_text: "black",
  red: 'rgba(240, 82, 82, 1)',
  dark_red: 'rgba(240, 82, 82, 0.8)',
  green: 'lightgreen'
};

const GlobalStyle = createGlobalStyle`
  :root {
  --light_primary: ${colors.light_primary};
  --primary: ${colors.primary};
  --purple: ${colors.purple};
  --border: ${colors.border};
  --shady: ${colors.shady};
  --second_primary: ${colors.second_primary};
  --secondary: ${colors.secondary};
  --accent: ${colors.accent};
  --second_accent: ${colors.second_accent};

  --background-light: ${colors.background_light};
  --ssecondary-light: ${colors.ssecondary_light};
  --background-dark: ${colors.background_dark};
  --ssecondary-dark: ${colors.ssecondary_dark};
  
  --text: ${colors.text};
  --page: ${colors.page};
  --second_text: ${colors.second_text};
  --red: ${colors.red};
  --dark_red: ${colors.dark_red};
  --periwinkle: ${colors.periwinkle};
  --green: ${colors.green};
  }
  .fade-in {
    animation: ${fadeIn} 1s ease-in-out;
  }
  body {
    background-color: var(--background-light);
    color: var(--text);
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;




export default GlobalStyle;
