import Vbox from "@shared/ui/layouts/Vbox";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import Text from "@shared/ui/Text";
import Hbox from "@shared/ui/layouts/Hbox";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import makeRequest from "@shared/api/makeRequest";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import Timer from "@shared/ui/Timer";


const ConfirmModal = ({id, amount, bank, payment_method, fiat, closeNotification, expires_at}) => {
    return <Vbox gap="10px">
        <Text weight="bold">Новая транзакция</Text>
        <Info title="Сумма" value={amount} />
        <Info title="Банк" value={bank} />
        <Info title="Метод оплаты" value={payment_method} />
        <Info title="Фиат" value={fiat} />
        <Info title="Время" value={<Timer endDate={new Date(expires_at)} />} />
        <Hbox>
            <SimpleButton onClick={deny} icon={<CloseOutlined />}>Отклонить</SimpleButton>
            <SimpleButton onClick={confirm} main icon={<CheckOutlined />}>Принять</SimpleButton>
        </Hbox>
    </Vbox>
    
    function confirm() {
        handleAsyncNotification(async () => {
            await makeRequest(`/api/v1/orders/surveys/accept?survey_id=${id}`, { method: "GET" });
            await closeNotification();
        }, {
            successText: "Принято",
            errorText: "Ошибка принятия",
            loadingText: "Принятие"
        })
    }

    function deny() {
        handleAsyncNotification(async () => {
            await makeRequest(`/api/v1/orders/surveys/deny?survey_id=${id}`, { method: "GET" });
            await closeNotification();
        }, {
            successText: "Отклонено",
            errorText: "Ошибка отклонения",
            loadingText: "Отлонение"
        })
    }
}

function Info({title, value}) {
    return <Hbox gap="10px">
        <Text size="small">{title}</Text>
        <Text>{value}</Text>
    </Hbox>
}

export default ConfirmModal;