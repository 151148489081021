import styled from "styled-components";


const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-size: 12px;
  color: ${props => props.theme.label};
  white-space: nowrap;
`;

const Label = ({value, children}) => {
    return <FormGroup>
        {value && <StyledLabel>{value}</StyledLabel>}
        {children}
    </FormGroup>
}

export default Label;