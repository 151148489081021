import { Checkbox as AntdCheckbox } from "antd";
import Text from "./Text";
import styled from "styled-components";

const StyledCheckbox = styled(AntdCheckbox)`
    color: ${props => props.theme.color};
`

const Checkbox = ({children, ...props}) => {
    return <StyledCheckbox {...props}>{children}</StyledCheckbox>
}

export default Checkbox;