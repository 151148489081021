import Icon from "@ant-design/icons";
import styled from "styled-components";

const LogoSvg = (props) => <svg {...props} height="100%" viewBox="0 0 167 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M128.748 0.00151895C139.529 0.00151895 150.307 -0.00313284 161.087 0.00384485C164.202 0.00617075 166.295 1.61337 166.874 4.44398C166.971 4.92312 166.992 5.42319 166.992 5.91628C166.999 11.9566 167.006 17.9993 166.994 24.0397C166.987 27.6611 164.857 29.8637 161.263 29.9474C159.385 29.9916 157.507 29.9474 155.629 29.9684C155.166 29.973 154.972 29.8591 154.949 29.3474C154.858 27.3378 154.84 27.3401 156.836 27.3401C158.281 27.3401 159.727 27.3541 161.17 27.3331C163.307 27.3052 164.378 26.2353 164.38 24.0978C164.392 18.0272 164.39 11.9566 164.38 5.88604C164.378 3.68574 163.328 2.64141 161.115 2.64141C139.584 2.63676 118.056 2.63676 96.5255 2.64141C94.3074 2.64141 93.2967 3.66248 93.2944 5.89767C93.2875 11.9683 93.2875 18.0389 93.2944 24.1094C93.2967 26.2586 94.3468 27.3634 96.4931 27.3076C97.4737 27.282 97.6195 27.6518 97.5894 28.4961C97.5316 30.0777 97.5732 30.08 96.0074 29.9474C92.6514 29.6614 90.66 27.5029 90.6508 24.0932C90.6369 18.0226 90.6323 11.952 90.6415 5.88139C90.6462 2.27392 92.9128 0.00617075 96.4931 0.00384485C107.248 -0.000806944 117.998 0.00151895 128.748 0.00151895Z" fill="currentColor"/>
    <path d="M0.0190624 15.3364C0.0190624 13.0431 0.0375654 10.7497 0.00518521 8.45639C-0.00406626 7.84468 0.183276 7.61674 0.789248 7.67954C1.18937 7.72141 1.59875 7.7121 2.00119 7.68187C2.63723 7.63535 3.0998 7.68187 3.14606 8.53547C3.18769 9.30767 3.61326 8.74945 3.82142 8.60757C5.69022 7.32135 7.71166 6.91199 9.91814 7.47253C10.8363 7.70512 11.6713 8.12146 12.2981 8.82156C12.793 9.37512 13.0821 9.43327 13.7159 8.92622C15.8506 7.21436 18.3139 6.80733 20.9089 7.57487C22.9442 8.17728 24.0683 9.72633 24.6095 11.7219C24.8454 12.5895 24.9772 13.478 24.9726 14.3874C24.9587 16.9715 24.9518 19.5556 24.9819 22.1396C24.9888 22.756 24.8685 23.0537 24.1793 22.9932C23.4901 22.9328 22.7916 22.9421 22.1023 22.9909C21.4732 23.0374 21.3275 22.7793 21.3345 22.1908C21.3622 19.7812 21.3506 17.3716 21.3414 14.9619C21.3391 14.4409 21.3368 13.9129 21.2651 13.3989C20.9968 11.4917 20.0739 10.5078 18.4758 10.3753C16.7342 10.231 15.1128 11.3033 14.5832 12.95C14.3681 13.6199 14.308 14.3107 14.3103 15.0108C14.3149 17.3925 14.2918 19.7719 14.3242 22.1536C14.3334 22.7886 14.1715 23.0491 13.5008 22.9932C12.8115 22.9351 12.1131 22.9444 11.4215 22.9909C10.8271 23.0305 10.6976 22.7862 10.7045 22.235C10.73 19.8254 10.7253 17.4157 10.7068 15.0061C10.7022 14.3991 10.6467 13.7873 10.5496 13.1896C10.3391 11.8731 9.77011 10.8148 8.35927 10.4683C6.92297 10.1148 5.34791 10.6799 4.49678 11.8382C3.83067 12.743 3.63176 13.7873 3.62945 14.8805C3.62251 17.3204 3.61095 19.7579 3.6387 22.1978C3.64564 22.7816 3.51381 23.0444 2.88008 22.9956C2.2186 22.9444 1.55018 22.9467 0.888701 22.9956C0.229533 23.0374 -0.0133177 22.8467 0.000559478 22.1303C0.0491297 19.8649 0.0190624 17.5995 0.0190624 15.3364Z" fill="currentColor"/>
    <path d="M105.574 21.5791C105.574 23.6189 105.549 25.4959 105.588 27.3729C105.602 28.0149 105.433 28.2661 104.769 28.2102C104.11 28.1567 103.439 28.1544 102.78 28.2102C102.1 28.2684 101.966 27.9846 101.971 27.359C101.999 24.1376 101.985 20.9185 101.985 17.6972C101.985 14.6223 101.999 11.5452 101.973 8.47034C101.969 7.87956 102.112 7.60045 102.739 7.67953C103.051 7.71907 103.379 7.72372 103.689 7.67953C104.577 7.55161 105.082 7.7935 105.119 8.81224C105.151 9.63561 105.572 9.10298 105.822 8.91691C108.928 6.59799 113.17 6.6794 115.989 9.11694C119.209 11.9034 119.697 17.0622 117.06 20.4487C114.488 23.7492 109.557 24.4213 106.09 21.9443C105.974 21.8582 105.856 21.7768 105.574 21.5791ZM115.152 15.3387C115.152 12.5034 113.01 10.3148 110.258 10.3334C107.512 10.352 105.44 12.4872 105.433 15.3038C105.424 18.1577 107.519 20.2905 110.325 20.2812C113.091 20.2743 115.152 18.1647 115.152 15.3387Z" fill="currentColor"/>
    <path d="M135.119 21.4069C132.915 23.2676 130.412 23.8211 127.662 23.1699C125.953 22.7652 124.535 21.8581 123.434 20.4672C121.096 17.5156 121.131 12.9429 123.538 10.0681C125.014 8.30741 126.934 7.42124 129.196 7.25378C131.425 7.08864 133.391 7.7771 135.075 9.23311C135.163 9.12147 135.218 9.08426 135.225 9.04006C135.401 7.68639 135.401 7.68639 136.745 7.68639C138.486 7.68639 138.486 7.68639 138.486 9.39825C138.486 13.636 138.472 17.8738 138.5 22.1116C138.505 22.7884 138.327 23.0931 137.631 22.9838C137.462 22.9582 137.284 22.9792 137.112 22.9792C135.503 22.9768 135.503 22.9768 135.119 21.4069ZM125.317 15.2735C125.303 18.1483 127.364 20.2928 130.153 20.309C132.894 20.3253 135.012 18.1901 135.036 15.3967C135.056 12.5661 132.952 10.3798 130.176 10.3565C127.435 10.3333 125.331 12.4615 125.317 15.2735Z" fill="currentColor"/>
    <path d="M41.7621 9.14692C41.7968 9.1004 41.8338 9.05621 41.8592 9.00504C42.0674 8.60266 41.7343 7.85372 42.2061 7.76301C43.0688 7.59787 43.987 7.663 44.8752 7.73278C45.2429 7.76069 45.0949 8.21191 45.0972 8.47474C45.1088 11.3472 45.1042 14.2197 45.1042 17.0922C45.1042 18.7738 45.0787 20.4578 45.118 22.1394C45.1319 22.7627 44.9908 23.0721 44.3108 22.9837C43.9408 22.9372 43.5568 22.9418 43.1868 22.9814C42.3958 23.0651 41.8777 22.9628 41.9031 21.9347C41.924 21.123 41.4591 21.644 41.2093 21.8207C38.4824 23.7652 35.5867 23.9513 32.6193 22.5208C29.4507 20.9927 27.8455 17.4783 28.4746 13.7336C29.0436 10.361 31.789 7.75836 35.2213 7.31179C37.4786 7.01872 39.5163 7.50949 41.3226 8.91898C41.4128 8.99109 41.503 9.06551 41.6025 9.12366C41.6441 9.15157 41.7065 9.14227 41.7621 9.14692ZM41.651 15.3687C41.6603 12.5171 39.5648 10.3564 36.7848 10.3564C34.044 10.3564 31.9393 12.5009 31.9324 15.3012C31.9254 18.1365 34.0486 20.3136 36.8171 20.3136C39.5648 20.3112 41.6395 18.1877 41.651 15.3687Z" fill="currentColor"/>
    <path d="M52.9636 9.21007C54.2194 8.34251 55.3389 7.71452 56.6318 7.42378C58.0819 7.09816 59.5113 7.17724 60.9106 7.59357C63.1494 8.26111 64.3914 9.86132 64.8748 12.1081C65.2194 13.7107 65.1778 15.3388 65.1709 16.9646C65.1639 18.7346 65.1547 20.507 65.1778 22.277C65.1847 22.791 65.0506 23.0236 64.5001 22.984C63.5542 22.9143 62.3422 23.3632 61.7293 22.8236C61.0933 22.263 61.5489 21.0187 61.5258 20.0767C61.4772 18.0462 61.572 16.0087 61.4518 13.9828C61.3153 11.736 60.3138 10.6731 58.3826 10.4149C56.3427 10.1404 54.5201 11.1941 53.8031 13.0594C53.5395 13.7432 53.4261 14.4526 53.4284 15.1876C53.4354 17.5391 53.4146 19.8906 53.4423 22.2421C53.4493 22.8119 53.2804 23.0166 52.7068 22.9864C51.9575 22.9445 51.2058 22.9585 50.4541 22.984C50.01 22.998 49.8389 22.8445 49.8389 22.3886C49.8481 17.6856 49.8481 12.9827 49.8389 8.27971C49.8389 7.83314 49.9892 7.63079 50.4425 7.68661C50.5859 7.70289 50.7316 7.68428 50.875 7.68894C51.8673 7.72615 53.2041 7.19585 52.9636 9.21007Z" fill="currentColor"/>
    <path d="M157.52 7.69558C156.437 10.2006 155.42 12.5637 154.397 14.9221C152.577 19.1204 150.745 23.314 148.944 27.5215C148.733 28.0146 148.497 28.2519 147.935 28.2263C146.929 28.1798 145.918 28.2123 144.762 28.2123C145.507 26.5749 146.154 25.0026 146.934 23.5001C147.378 22.6418 147.343 21.951 146.955 21.0765C145.086 16.8689 143.305 12.6218 141.462 8.40033C141.205 7.80955 141.265 7.64208 141.919 7.67232C142.812 7.71186 143.71 7.69558 144.605 7.67697C144.966 7.66999 145.188 7.77466 145.336 8.13052C146.67 11.3682 148.021 14.6012 149.418 17.9644C150.058 16.4293 150.671 14.9617 151.28 13.4917C151.99 11.7799 152.711 10.0726 153.391 8.34683C153.576 7.87235 153.805 7.66069 154.333 7.68162C155.341 7.72581 156.352 7.69558 157.52 7.69558Z" fill="currentColor"/>
    <path d="M75.5568 17.9925C76.889 14.7641 78.1935 11.6428 79.4563 8.50514C79.6945 7.91203 79.9836 7.62827 80.6497 7.67944C81.4245 7.73992 82.2086 7.70736 82.988 7.67944C83.52 7.66084 83.6403 7.77015 83.3997 8.30744C82.2942 10.7938 81.2326 13.2988 80.1525 15.7968C78.4502 19.7276 76.734 23.6514 75.0526 27.5915C74.8444 28.0799 74.5877 28.2381 74.0858 28.2218C73.0774 28.1892 72.0667 28.2125 70.9079 28.2125C71.4006 27.1146 71.7359 26.0191 72.3373 25.1027C73.5978 23.1746 73.4058 21.4418 72.4067 19.4066C70.6373 15.7992 69.1941 12.0312 67.5774 8.34698C67.3184 7.7562 67.4502 7.65619 68.0168 7.67944C69.0808 7.72131 70.3251 7.39103 71.16 7.84226C71.9695 8.27953 72.0736 9.6332 72.4807 10.5868C73.5168 13.0057 74.5044 15.4456 75.5568 17.9925Z" fill="currentColor"/>
    <path d="M125.792 29.9479C121.086 29.9479 116.377 29.9339 111.67 29.9642C110.955 29.9688 110.729 29.7828 110.747 29.0478C110.791 27.3382 110.752 27.3382 112.41 27.3382C121.594 27.3359 130.779 27.3429 139.961 27.3103C140.729 27.308 140.949 27.5057 140.925 28.2756C140.875 29.9572 140.916 29.9595 139.221 29.9595C134.745 29.9595 130.268 29.9595 125.792 29.9595C125.792 29.9549 125.792 29.9525 125.792 29.9479Z" fill="currentColor"/>
</svg>

const LogoIcon = styled((props) => <Icon style={{fontSize: "120px"}} {...props} component={LogoSvg} />)`
    color: ${props => props.theme.color};
`

export default LogoIcon;