import React from "react";
import styled from "styled-components";

// Фильтрация нестандартных пропсов
const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["customJust", "wrap", "width", "flex", "height"].includes(prop),
})`
  display: flex;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "unset"};
  justify-content: ${(props) => props.customJust || "space-between"};
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
`;

const HBox = ({
  children,
  customJust,
  wrap,
  gap,
  alignItems,
  backGround,
  borderRadius,
  width,
  flex,
  height,
  borderBottom,
  padding,
}) => {
  return (
    <StyledDiv
      wrap={wrap}
      customJust={customJust}
      style={{
        gap: gap,
        alignItems: alignItems,
        background: backGround,
        borderRadius: borderRadius,
        borderBottom: borderBottom,
        padding: padding,
      }}
      width={width}
      flex={flex}
      height={height}
    >
      {children}
    </StyledDiv>
  );
};

export default HBox;
