import React from "react";
import { aligns, sizes, spacing, weights } from "../../app/constants";
import styled from "styled-components";

const ColoredText = styled.span`
    font-weight: ${(props) => weights[props.$weight]};
    font-size: ${(props) => sizes[props.$size]};
    font-family: "Manrope";
    letter-spacing: ${(props) => spacing[props.$space]};
    text-align: ${(props) => aligns[props.$type] || "center"};
    line-height: 1.5;
    max-width: 100%;
`;

const Text = ({
    children,
    space = "default",
    weight = "default",
    size = "default",
    type = "basic",
}) => {
    return (
        <ColoredText
            $space={space}
            $weight={weight}
            $size={size}
            $type={type}
        >
            {children}
        </ColoredText>
    );
};

export default Text;
