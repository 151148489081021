// src/routes/adminRoutes.js
import React, { lazy } from 'react';

const AdminAssets = lazy(() => import('@admin/Assets'));
const AdminOrders = lazy(() => import('@src/widgets/orders'));
const AdminShops = lazy(() => import('@admin/Shops'));
const AdminShopTraderAssosiations = lazy(() => import('@admin/ShopTraderAssosiations'));
const AdminTraders = lazy(() => import('@admin/Traders'));
const PHubs = lazy(() => import('@admin/PHubs'))
const AdminStatistics = lazy(() => import('@admin/Statistics'));
const Trader = lazy(() => import('@admin/Trader'));
const AdminHubs = lazy(() => import('@admin/Hubs'));
const Shop = lazy(() => import('@admin/Shop'));
const HubRequisites = lazy(() => import('@admin/Requisites'));
const PhubsRequests = lazy(() => import('@admin/PHubsRequests'));
const AdminArchive = lazy(() => import('@admin/Archive'));
const AdminSettings = lazy(() => import('@admin/Settings'));
const AdminAgentAssosiations = lazy(() => import('@admin/AgentAssosiations'));
const AdminAgents = lazy(() => import('@admin/Agents'));
const CascadeHubs = lazy(() => import('@admin/CascadeHubs'));
const AdminCascades = lazy(() => import('@admin/Cascades'));
const Order = lazy(() => import('@src/widgets/Order'));

const adminRoutes = [
    { path: '/assets', element: <AdminAssets /> },
    { path: '/orders', element: <AdminOrders /> },
    { path: '/orders/:order_id', element: <Order /> },
    { path: '/shops', element: <AdminShops /> },
    { path: '/shops/:shop_id/traders/:trader_id', element: <AdminShopTraderAssosiations /> },
    { path: '/shops/:shop_id', element: <Shop /> },
    { path: '/shops/:shop_id/cascades/:cascade_id/hubs', element: <CascadeHubs /> },
    { path: '/test', element: <AdminShopTraderAssosiations /> },
    { path: '/traders/:trader_id', element: <Trader /> },
    { path: '/agents', element: <AdminAgents /> },
    { path: '/agents/:agent_id', element: <AdminAgentAssosiations /> },
    { path: '/traders', element: <AdminTraders /> },
    { path: '/cascades', element: <AdminCascades /> },
    { path: '/statistics', element: <AdminStatistics /> },
    { path: '/traders/:trader_id/hubs', element: <AdminHubs /> },
    { path: '/traders/:trader_id/hubs/:hub_id', element: <HubRequisites /> },
    { path: '/traders/:trader_id/phubs', element: <PHubs /> },
    { path: '/traders/:trader_id/phubs/:phub_id', element: <PhubsRequests /> },
    { path: '/archive', element: <AdminArchive /> },
    { path: '/settings', element: <AdminSettings /> },
];

export default adminRoutes;
